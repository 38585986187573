import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import BannerForm, { BannerTypes } from '../../../components/forms/BannerForm.js';
import ExistingBanner from '../../../components/existing-banners/ExistingBanner.js';
import Layout from '../../../components/Layout.js';
import Loading from '../../../components/Loading.js';
import Status, { isLoadingStatus } from '../../../stores/Status.js';
import TallContainer from '../../../components/TallContainer.js';
import Title from '../../../components/typography/Title.js';
import { loadSportBanner } from '../../../stores/ExistingBanners/ExistingBannersActions.js';
import { useExistingBanner } from '../../../stores/ExistingBanners/ExistingBannersSelectors.js';
import { useSportById } from '../../../stores/Sports/SportsSelectors.js';

const SportDelegate = ({ id }) => {
  const dispatch = useDispatch();
  const sport = useSportById(id);
  const { status } = useExistingBanner();

  useEffect(() => {
    if (id) {
      dispatch(loadSportBanner(id)).then();
    }
  }, [id, dispatch]);

  if (isLoadingStatus(status)) {
    return <Loading />;
  }

  if (status === Status.OK) {
    return <ExistingBanner type={BannerTypes.Sport} sport={sport} />;
  }

  return (
    <div>
      <p className="banner__description">
        There isn't an active banner for {sport.name} at the moment. You can add one below...
      </p>
      <BannerForm type={BannerTypes.Sport} sport={sport} />
    </div>
  );
};

const withSportTitle = id => () => {
  const { name } = useSportById(id);

  return name
    ? <Title black={name} blue="Banner" />
    : null;
};

export default function SportBanner({ params: { sportId } }) {
  if (!sportId) {
    return <Loading />;
  }

  return (
    <Layout TitleElement={withSportTitle(sportId)}>
      <TallContainer>
        <SportDelegate id={sportId} />
      </TallContainer>
    </Layout>
  );
};
